<template>
  <div class="box">
    <div class="smallBox">
      <ul class="photo">
        <li class="img_left">
          <img
            @click="dow()"
            src="../assets/images/download2x.png"
            alt=""
            class="download"
          />
        </li>
        <li class="img_right">
          <img src="../assets/images/share2x.png" alt="" />
        </li>
      </ul>
    </div>
    <div class="bigBox" ref="tb_price">
      <h2>{{GLOBAL}}</h2>
      <h1>每日报价</h1>
      <div class="tb_price">
        <img src="../assets/images/z.png" alt="" class="left" />
        <div class="tb">
          <p>{{ timecenter }}</p>
          <div class="price_box">
            <ul>
              <li>类别</li>
              <li>回购</li>
              <li>销售</li>
              <li>黄金<br />9999金条</li>
              <li class="big">
                {{ arrcenter.huangjin[0].productData["huigou"] }}
              </li>
              <li class="big">
                {{ arrcenter.huangjin[0].productData["xiaoshou"] }}
              </li>
              <li>黄金<br />999首饰</li>
              <li class="big">
                {{ arrcenter.huangjin[2].productData["huigou"] }}
              </li>
              <li></li>
              <li>铂金 950</li>
              <li class="big">
                {{ arrcenter.bojin[3].productData["huigou"] }}
              </li>
              <li></li>
              <li>钯金 990</li>
              <li class="big">
                {{ arrcenter.bajin[2].productData["huigou"] }}
              </li>
              <li></li>
            </ul>
          </div>
        </div>
        <img src="../assets/images/y.png" alt="" class="right" />
      </div>
      <p class="company">北京市东城区法华寺街天雅珠宝城一楼 1076</p>
      <p class="company">深圳市罗湖区田贝3路 船舶大厦6楼608室</p>
      <p class="xx">客服电话： 4001-886-722</p>
      <img src="../assets/images/app.png" alt=""  />
      <p class="xx">长按二维码，查看实时报价！</p>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
import Stomp from "stompjs";
import router from "../router/index";
import html2canvas from "html2canvas";
// import { getStyle } from 'node_modules/highcharts/highcharts';
export default {
  name: "Home",
  metaInfo: {
    meta: [{ name: "viewport", content: "initial-scale=0.6" }]
  },
  data() {
    return {
      sendmsg: false,
      onedata: [],
      // 旧值判断
      isfirstval: false,
      // 克隆后获取之前的值做比较
      lastarr: [],
      // 深度克隆后的赋值
      updataarr: [],
      firstload: true,
      wsPrice: null,
      prePrice: {},
      isload: true,
      arrcenter: {
        huangjin: [],
        bojin: [],
        bajin: [],
        baiyin: [],
        shangjinsuo: [],
        guojixianhuo: []
      },
      newarrlist: [],
      // 是否开盘中
      opentrade: false,
      havedata: false,
      timecenter: "",

      client: {},
      productall: {
        huangjin: [],
        bojin: [],
        bajin: [],
        baiyin: [],
        shangjinsuo: [],
        guojixianhuo: []
      },
      new_tuisong_val_last: {},
      new_tuisong_val_before: {},

      Compareval: {
        huangjin: [],
        bojin: [],
        bajin: [],
        baiyin: [],
        shangjinsuo: [],
        guojixianhuo: []
      }
    };
  },
  mounted() {
    const that = this;
    this.connect();
    // setInterval(() => {
    //   console.log(that.onedata);
    //   const t = that.onedata["time"];
    //   let date = new Date(Number(t) * 1000);
    //   if (isNaN(t)) {
    //     date = new Date();
    //   }
    //   // debugger
    //   this.timebox(date);
    // }, 1000);

    this.$eventBus.$on("onclickLists", () => {
      this.dow();
      console.log("this.dow();");
    });
  },
  updated() {
    // setInterval(() => {
    const t = this.onedata["time"];

    let date = new Date(Number(t) * 1000);
    if (isNaN(t)) {
      date = new Date();
    }

    const year = new Date(Number(t) * 1000).getFullYear();
    const month = new Date(Number(t) * 1000).getMonth() + 1;
    const day = new Date(Number(t) * 1000).getDate();
    const hour = new Date(Number(t) * 1000).getHours();
    const Min = new Date(Number(t) * 1000).getMinutes();
    const Seconds = new Date(Number(t) * 1000).getSeconds();
    // console.log(year);
    // console.log(month);
    // console.log(day);
    // console.log(hour);
    // console.log(Min);
    // console.log(Seconds);
    if (hour == "10" && Min == "00" && Seconds == "00") {
      console.log(hour + "时" + Min + "分" + Seconds + "秒");
      // this.$eventBus.$emit("onclickLists");
      this.dow();
    } else {
      console.log(
        year +
          "年" +
          month +
          "月" +
          day +
          "日" +
          hour +
          "时" +
          Min +
          "分" +
          Seconds +
          "秒"
      );
    }
    // this.timebox(date);
    // });
  },
  computed: {},
  watch: {
    onedata: function(newN, oldN) {
      const than = this;

      // console.log(newN);
      Object.entries(newN).forEach(([typeKey, type]) => {
        if (["time", "trade", "id"].includes(typeKey)) {
          return;
        }
        Object.entries(type.prices).forEach(([goodsKey, goods]) => {
          //  debugger
          let huigouColor = "redfont";
          if (oldN && !Array.isArray(oldN)) {
            const oldGoods = oldN[typeKey].prices[goodsKey];
            const oldHuigou = oldGoods["huigou"];
            const newHuigou = goods["huigou"];
            const oldHuigouColor = oldGoods["huigouColor"];
            // console.log(newHuigou, oldHuigou);
            // console.log(oldHuigouColor);
            // debugger
            huigouColor =
              newHuigou < oldHuigou
                ? "greenfont"
                : newHuigou == oldHuigou
                ? oldHuigouColor
                : "redfont";
          }
          goods["huigouColor"] = huigouColor;
          // debugger
          // console.log(huigouColor);
        });
      });
      if (oldN instanceof Array) {
      } else {
        this.new_tuisong_val_before = JSON.parse(JSON.stringify(oldN));
        this.new_tuisong_val_last = JSON.parse(JSON.stringify(newN));
        this.sendmsg = true;
        this.newlistsort(this.new_tuisong_val_last);
      }
    }
  },
  filters: {
    changeTwoDecimal_f(x) {
      var f_x = parseFloat(x);
      if (isNaN(f_x)) {
        return 0;
      }
      // Math.round
      var f_x = (x * 100) / 100;
      let s_x = f_x.toString();
      let pos_decimal = s_x.indexOf(".");
      if (pos_decimal < 0) {
        pos_decimal = s_x.length;
        s_x += ".";
      }
      while (s_x.length <= pos_decimal + 2) {
        s_x += "0";
      }
      return s_x;
    },
    changeTwoDecimal_fthree(x) {
      var f_x = parseFloat(x);
      if (isNaN(f_x)) {
        return 0;
      }
      // Math.round
      var f_x = (x * 100) / 100;
      let s_x = f_x.toString();
      let pos_decimal = s_x.indexOf(".");
      if (pos_decimal < 0) {
        pos_decimal = s_x.length;
        s_x += ".";
      }
      while (s_x.length <= pos_decimal + 3) {
        s_x += "0";
      }
      if (s_x.length >= 7) {
        return Number(s_x).toFixed(3);
      } else {
        return s_x;
      }
    }
  },
  methods: {
    // timebox(date) {
    //   // const year = new Date().getFullYear();
    //   // const month = new Date().getMonth() + 1;
    //   // const day = new Date().getDate();
    //   // const hour = new Date().getHours();
    //   // const Min = new Date().getMinutes();
    //   // const getSeconds = new Date().getSeconds();

    //   const year = date.getFullYear();
    //   const month = date.getMonth() + 1;
    //   const day = date.getDate();
    //   const hour = date.getHours();
    //   const Min = date.getMinutes();
    //   const getSeconds = date.getSeconds();

    //   // console.log(this.year, this.month, this.day);

    // if (hour == "10" && Min == "00" && getSeconds == "00") {
    //   console.log(hour + "时" + Min + "分" + getSeconds + "秒");

    //   // this.$eventBus.$emit("onclickLists");
    //   this.dow();
    // }
    // },
    //图片格式转换方法
    dataURLToBlob(dataurl) {
      const arr = dataurl.split(",");
      const mime = arr[0].match(/:(.*?);/)[1];
      const bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], { type: mime });
    },
    //点击生成页面截图方法
    dow() {
      const canvasID = this.$refs["tb_price"];
      const a = document.createElement("a");
      html2canvas(canvasID).then(canvas => {
        const dom = document.body.appendChild(canvas);
        dom.style.display = "none";
        a.style.display = "none";
        document.body.removeChild(dom);
        const blob = this.dataURLToBlob(dom.toDataURL("image/png"));
        a.setAttribute("href", URL.createObjectURL(blob));
        //这块是保存图片操作  可以设置保存的图片的信息
        const year =
          new Date(Number(this.onedata["time"]) * 1000).getFullYear() + "年";
        const month =
          new Date(Number(this.onedata["time"]) * 1000).getMonth() + 1 + "月";
        const day =
          new Date(Number(this.onedata["time"]) * 1000).getDate() + "日";
        const everyDay = year + "-" + month + "-" + day + "的报价";
        a.setAttribute("download", everyDay + ".png");
        document.body.appendChild(a);
        a.click();
        URL.revokeObjectURL(blob);
        document.body.removeChild(a);
      });
    },
    newlistsort(data) {
      const than = this;
      than.arrcenter.huangjin = [];
      than.arrcenter.bojin = [];
      than.arrcenter.bajin = [];
      than.arrcenter.baiyin = [];
      than.arrcenter.guojixianhuo = [];
      than.arrcenter.shangjinsuo = [];

      than.productall.huangjin = [];
      than.productall.bojin = [];
      than.productall.bajin = [];
      than.productall.baiyin = [];
      than.productall.shangjinsuo = [];
      const resbody = data;
      for (const i in resbody) {
        if (resbody[i].name === "黄金") {
          for (const b in resbody[i].prices) {
            if (resbody[i].prices[b]["display"] == "SHOW") {
              this.arrcenter.huangjin.push({
                engname: b,
                sort: resbody[i].prices[b].sort,
                productData: resbody[i].prices[b]
              });
            }
          }
          this.arrcenter.huangjin.sort(this.compare("sort"));
          // this.productall.huangjin = resbody[i].prices
        }
        if (resbody[i].name === "铂金") {
          for (const b in resbody[i].prices) {
            if (resbody[i].prices[b]["display"] == "SHOW") {
              this.arrcenter.bojin.push({
                engname: b,
                sort: resbody[i].prices[b].sort,
                productData: resbody[i].prices[b]
              });
            }
          }
          this.arrcenter.bojin.sort(this.compare("sort"));
        }
        if (resbody[i].name === "钯金") {
          for (const b in resbody[i].prices) {
            if (resbody[i].prices[b]["display"] == "SHOW") {
              this.arrcenter.bajin.push({
                engname: b,
                sort: resbody[i].prices[b].sort,
                productData: resbody[i].prices[b]
              });
            }
          }
          this.arrcenter.bajin.sort(this.compare("sort"));
        }
        if (resbody[i].name === "白银") {
          for (const b in resbody[i].prices) {
            if (resbody[i].prices[b]["display"] == "SHOW") {
              this.arrcenter.baiyin.push({
                engname: b,
                sort: resbody[i].prices[b].sort,
                productData: resbody[i].prices[b],
                time: resbody.time
              });
            }
          }
          this.arrcenter.baiyin.sort(this.compare("sort"));
        }
        if (resbody[i].name === "上金所") {
          for (const b in resbody[i].prices) {
            if (resbody[i].prices[b]["display"] == "SHOW") {
              this.arrcenter.shangjinsuo.push({
                engname: b,
                sort: resbody[i].prices[b].sort,
                productData: resbody[i].prices[b]
              });
            }
          }
          this.arrcenter.shangjinsuo.sort(this.compare("sort"));
        }
        if (resbody[i].name === "国际现货") {
          for (const b in resbody[i].prices) {
            if (resbody[i].prices[b]["display"] == "SHOW") {
              this.arrcenter.guojixianhuo.push({
                engname: b,
                sort: resbody[i].prices[b].sort,
                productData: resbody[i].prices[b]
              });
            }
          }
          this.arrcenter.guojixianhuo.sort(this.compare("sort"));
        }
      }
    },
    // 排序
    compare(property) {
      return function(a, b) {
        const value1 = a[property];
        const value2 = b[property];
        return value1 - value2;
      };
    },
    sortNumber(a) {
      return a;
    },
    unzip(b64Data) {
      let strData = atob(b64Data);
      const charData = strData.split("").map(function(x) {
        return x.charCodeAt(0);
      });
      const binData = new Uint8Array(charData);
      const data = pako.inflate(binData);
      strData = String.fromCharCode.apply(null, new Uint16Array(data));
      return decodeURIComponent(escape(strData));
    },
    onConnected() {
      // console.log(this.client)
      const topic = "/price/all";
      // let topic = '/location'
      this.client.subscribe(topic, this.responseCallback, this.onFailed);
    },
    connect() {
      this.client = Stomp.over(new WebSocket(process.env.VUE_APP_WS));
      // ---初始化mqtt客户端，并连接mqtt服务
      const headers = {
        login: "username",
        passcode: "password"
      };
      this.client.connect(headers, this.onConnected, this.onFailed);
      this.client.debug = null;
    },
    onFailed(frame) {
      console.log("错误: " + frame);
      console.log(this.client);
      if (!this.client.connected) {
        this.opentrade = false;
        setTimeout(() => {
          this.connect();
        }, 1000);
      }
    },
    deepObjectMerge(FirstOBJ_, SecondOBJ) {
      // console.log("函数内的数据")
      const FirstOBJ = FirstOBJ_ || this.wsPrice;
      for (const key in SecondOBJ) {
        FirstOBJ[key] =
          FirstOBJ[key] && FirstOBJ[key].toString() === "[object Object]"
            ? this.deepObjectMerge(FirstOBJ[key], SecondOBJ[key])
            : (FirstOBJ[key] = SecondOBJ[key]);
      }
      return FirstOBJ;
    },
    deepMerge(...objs) {
      const than = this;
      const result = Object.create(null);
      objs.forEach(obj => {
        if (obj) {
          Object.keys(obj).forEach(key => {
            const val = obj[key];
            if (than.isPlainObject(val)) {
              // 递归
              if (than.isPlainObject(result[key])) {
                result[key] = than.deepMerge(result[key], val);
              } else {
                result[key] = than.deepMerge(val);
              }
            } else {
              //  数组也要重新赋值  不然依然会引用到其他的
              if (Array.isArray(val)) {
                result[key] = [...val];
              } else {
                result[key] = val;
              }
            }
          });
        }
      });
      return result;
    },
    isPlainObject(val) {
      const toString = Object.prototype.toString;
      return toString.call(val) === "[object Object]";
    },
    extend() {
      const than = this;
      const extended = {};
      let deep = false;
      let i = 0;
      // 判断是否为深拷贝
      if (Object.prototype.toString.call(arguments[0]) === "[object Boolean]") {
        deep = arguments[0];
        i++; //如果为深拷贝则初始的i为1或者为0
      }

      // 将对象属性合并到已存在的对象中
      const merge = function(obj) {
        for (const prop in obj) {
          if (obj.hasOwnProperty(prop)) {
            // 如果属性为对象并且需要深拷贝时则使用函数递归、反之则将当前的属性替换现有的属性
            if (
              deep &&
              Object.prototype.toString.call(obj[prop]) === "[object Object]"
            ) {
              extended[prop] = than.extend(extended[prop], obj[prop]);
            } else {
              extended[prop] = obj[prop];
            }
          }
        }
      };
      // 遍历所有对象属性
      for (; i < arguments.length; i++) {
        merge(arguments[i]);
      }
      return extended;
    },
    responseCallback(frame) {
      const than = this;
      // than.onedata = []

      const res_data = eval("[" + this.unzip(frame.body) + "]")[0];
      const parse_res = JSON.parse(JSON.stringify(res_data));
      if (!this.firstload) {
        than.onedata = than.deepMerge(than.wsPrice, parse_res);
        // --------------------
        than.wsPrice = than.onedata;
        // --------------------
      } else {
        than.firstload = false;
        than.updataarr = parse_res;
        than.wsPrice = parse_res;
      }

      const date = new Date(Number(than.onedata["time"]) * 1000);
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();
      const hour = date.getHours();
      const Min = date.getMinutes();
      const getSeconds = date.getSeconds();
      this.timecenter =
        year +
        "年" +
        (month <= 9 ? "0" + month : month) +
        "月" +
        (day <= 9 ? "0" + day : day) +
        "日" +
        "\u3000" +
        (hour <= 9 ? "0" + hour : hour) +
        "时" +
        (Min <= 9 ? "0" + Min : Min) +
        "分" +
        (getSeconds <= 9 ? "0" + getSeconds : getSeconds) +
        "秒";
      console.debug(parse_res);
      //  是否开盘中
      this.opentrade = parse_res.trade;
      if (this.isload) {
        let resbody = "";
        if (
          JSON.stringify(than.new_tuisong_val_last) == "{}" &&
          !this.disableDataChange
        ) {
          resbody = than.updataarr;
          this.newlistsort(resbody);
        } else {
        }
      }
      this.isload = true;
      this.havedata = true;
    },
    callPhone() {
      window.location.href = "tel:// 4001-886-722";
    },
    addRouterMap() {
      this.$router.push("/aboutUs");
    }
  },
  destroyed() {
    clearTimeout(this.setInterval);
    this.client.disconnect(function() {
      // 断开连接
      // console.log("See you next time!");
    });
  },
  components: {}
};
</script>
<style scoped="true">
.smallBox {
  position: relative;
  z-index: 99999;
}
.bigBox {
  position: relative;
  width: 375pt;
  background-color: #ffffff;
  margin: auto;
}
.photo .img_left {
  float: left;
  /* margin-top: -38.5pt; */
}
.photo .img_right {
  float: right;
  /* margin-top: -39pt; */
}
.box .photo li img {
  width: 25pt;
  height: 25pt;
}
.smallBox .photo {
  position: sticky;
  top: 10pt;
  width: 100%;
  height: 25px;
  /* margin-top: 5pt; */
  padding: 13pt 20pt;
}
.box p:last-child {
  margin-top: 0;
}
.bigBox > img {
  display: block;
  width: 120pt;
  height: 140pt;
  text-align: center;
  padding-top: 20pt;
}
.box .company {
  font-size: 11pt;
}
.bigBox > .company:first-of-type {
  margin: 20pt 0 8pt 0;
}
.box .xx {
  font-size: 9pt;
  margin-top: 9pt;
  /* padding-bottom: 10pt; */
}
.box .xx:last-child {
  padding-bottom: 30pt;
}
.box p {
  margin-bottom: 10pt;
}
.box p,
.box img {
  margin: auto;
  text-align: center;
  color: #65504d;
}
.tb_price p {
  font-size: 10pt;
  margin: 8pt 0;
}
.tb_price .tb > .price_box > ul > .big {
  font: normal 900 13pt/40pt MicrosoftYaHei-Bold;
}
.price_box ul li:nth-child(4),
.price_box ul li:nth-child(7) {
  font: normal 600 11pt MicrosoftYaHei;
  line-height: 12pt;
  padding-top: 7pt;
}
.price_box ul li:nth-child(10),
.price_box ul li:nth-child(13) {
  font: normal 600 11pt MicrosoftYaHei;
  padding: 13pt 0 0 3pt;
}

.price_box ul li:nth-child(9)::before,
.price_box ul li:nth-child(12)::before,
.price_box ul li:nth-child(15)::before {
  position: absolute;
  top: 1pt;
  left: 9pt;
  content: "";
  width: 35pt;
  height: 20pt;
  border-bottom: 2px solid #65504d;
  transform: rotate(-27deg);
}

.price_box ul li {
  position: relative;
  float: left;
  width: 62pt;
  height: 40pt;
  border-right: 1px solid black;
  border-bottom: 1px solid black;
  line-height: 40pt;
  color: #65504d;
}
.tb_price .tb {
  text-align: center;
}
.price_box ul {
  float: left;
  width: 187pt;
  height: 200pt;
  border: 1px solid #685350;
  border-bottom: 0;
  border-right: 0;
  font: normal 600 17pt MicrosoftYaHei-Bold;
  margin: 0 24pt;
}
.tb_price .tb {
  width: 240pt;
  height: 244pt;
  border: 2pt solid #f6e686;
  border-radius: 18pt;
  margin-top: 41.5pt;
  margin-left: -10pt;
}
.tb_price .right {
  position: absolute;
  width: 73.5pt;
  height: 207.5pt;
  top: 82pt;
  right: 8pt;
}
.tb_price .left {
  width: 82.5pt;
  height: 236.5pt;
  margin-top: 15%;
}
.tb_price {
  position: relative;
  margin-left: -3%;
  overflow: hidden;
}
.tb_price img,
.tb_price div {
  float: left;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
li {
  list-style: none;
}
.box {
  position: relative;
  height: 730pt;
  width: 375pt;
  background-color: #ffffff;
  margin: auto;
}
h2 {
  width: 100%;
  color: #f9ab2f;
  font: italic 900 35pt yixinchangyouti;
  text-align: center;
  padding-top: 30pt;
}
h1 {
  width: 100%;
  color: #f9ab2f;
  font: italic 900 44pt yixinchangyouti;
  text-align: center;
}
</style>
