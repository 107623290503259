var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "box" }, [
    _c("div", { staticClass: "smallBox" }, [
      _c("ul", { staticClass: "photo" }, [
        _c("li", { staticClass: "img_left" }, [
          _c("img", {
            staticClass: "download",
            attrs: { src: require("../assets/images/download2x.png"), alt: "" },
            on: {
              click: function($event) {
                return _vm.dow()
              }
            }
          })
        ]),
        _vm._m(0)
      ])
    ]),
    _c("div", { ref: "tb_price", staticClass: "bigBox" }, [
      _c("h2", [_vm._v(_vm._s(_vm.GLOBAL))]),
      _c("h1", [_vm._v("每日报价")]),
      _c("div", { staticClass: "tb_price" }, [
        _c("img", {
          staticClass: "left",
          attrs: { src: require("../assets/images/z.png"), alt: "" }
        }),
        _c("div", { staticClass: "tb" }, [
          _c("p", [_vm._v(_vm._s(_vm.timecenter))]),
          _c("div", { staticClass: "price_box" }, [
            _c("ul", [
              _c("li", [_vm._v("类别")]),
              _c("li", [_vm._v("回购")]),
              _c("li", [_vm._v("销售")]),
              _vm._m(1),
              _c("li", { staticClass: "big" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.arrcenter.huangjin[0].productData["huigou"]) +
                    " "
                )
              ]),
              _c("li", { staticClass: "big" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.arrcenter.huangjin[0].productData["xiaoshou"]) +
                    " "
                )
              ]),
              _vm._m(2),
              _c("li", { staticClass: "big" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.arrcenter.huangjin[2].productData["huigou"]) +
                    " "
                )
              ]),
              _c("li"),
              _c("li", [_vm._v("铂金 950")]),
              _c("li", { staticClass: "big" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.arrcenter.bojin[3].productData["huigou"]) +
                    " "
                )
              ]),
              _c("li"),
              _c("li", [_vm._v("钯金 990")]),
              _c("li", { staticClass: "big" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.arrcenter.bajin[2].productData["huigou"]) +
                    " "
                )
              ]),
              _c("li")
            ])
          ])
        ]),
        _c("img", {
          staticClass: "right",
          attrs: { src: require("../assets/images/y.png"), alt: "" }
        })
      ]),
      _c("p", { staticClass: "company" }, [
        _vm._v("北京市东城区法华寺街天雅珠宝城一楼 1076")
      ]),
      _c("p", { staticClass: "company" }, [
        _vm._v("深圳市罗湖区田贝3路 船舶大厦6楼608室")
      ]),
      _c("p", { staticClass: "xx" }, [_vm._v("客服电话： 4001-886-722")]),
      _c("img", {
        attrs: { src: require("../assets/images/app.png"), alt: "" }
      }),
      _c("p", { staticClass: "xx" }, [_vm._v("长按二维码，查看实时报价！")])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", { staticClass: "img_right" }, [
      _c("img", {
        attrs: { src: require("../assets/images/share2x.png"), alt: "" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [_vm._v("黄金"), _c("br"), _vm._v("9999金条")])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [_vm._v("黄金"), _c("br"), _vm._v("999首饰")])
  }
]
render._withStripped = true

export { render, staticRenderFns }